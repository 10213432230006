import {createSlice, current} from "@reduxjs/toolkit";

export const languageSlice = createSlice({
    name: "language",
    initialState: {
        language: {
            route: "",
            language: "",
            currencyForPay: '',
            currency: "",
            currencies: []
        },
        headerHeight: 60
    },
    reducers: {
        setHeaderHeight(state, action) {
            state.headerHeight = action.payload
        },
        setCurrencies(state, action) {
            const tmp = []
            action.payload.forEach(data => {
                tmp.push({
                    currencyForPay: data.attributes.currencyForPay,
                    defaultFor: data.attributes.defaultFor,
                    languages: data.attributes.languages,
                    label: data.attributes.currencies,
                    value: data.attributes.multiplier,
                    symbol: data.attributes.symbol
                })
            })
            state.language.currencies = tmp
            //state.language.EURInDolar = action.payload.EURInDolar
            // state.language.CZKInDolar = action.payload.CZKInDolar
            //state.language.UAHInDolar = action.payload.UAHInDolar
        },
        changeLanguageCurrency(state, action) {

            state.language.currency = action.payload[0].toUpperCase();
            state.language.currencyForPay = action.payload[1];
        },
        changeLanguage: (state, action) => {
            state.language.route = action.payload.route;
            //console.log(action.payload.language, current(state.language.currencies));
            state.language.language = action.payload.language
           
            try {
                const find = current(state.language.currencies).find(curr => {
                    if (!curr.defaultFor) return false
                    return curr.defaultFor.split(',').some(k => {
                        return k.toUpperCase() === action.payload.language.toUpperCase()
                    })
                })
                if (find) {

                    state.language.currency = find.label
                    state.language.currencyForPay = find.currencyForPay
                } else {
                    console.log('currency find not found');
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
});

export const {changeLanguage, setHeaderHeight, setCurrencies, changeLanguageCurrency} = languageSlice.actions;

export default languageSlice.reducer;
