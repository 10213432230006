import React, {useEffect, useRef, useState} from 'react';
import HeaderListItem from '../HeaderListItem';
import HeaderListMobile from '../HeaderListMobile';
import './header.scss';
import {Link} from 'react-router-dom';
import Drawer from '../Drawer';
import useFetch from '../../Hooks/useFetch';
import {useDispatch, useSelector} from 'react-redux';
import {changeLanguage, setHeaderHeight} from '../../Redux/languageReducer';
import Skeleton from 'react-loading-skeleton';
import {setOpenCart} from "../../Redux/cartReducer";
import SelectCurrency from "../SelectCurrency/selectCurrency";


function Header() {


    const languageInformation = useSelector(state => state.language.language);
    const {data: text, loading, error} = useFetch(process.env.REACT_APP_API_URL + `/ells?locale=${languageInformation.language}&populate=*`);


    const [burgerOpen, setBurgerOpen] = useState(false);
    const [searchBody, setSearchBody] = React.useState('');

    const dispatch = useDispatch();

    const token = useSelector(state => state.login.login);


    const [languageOpen, setLanguageOpen] = useState(false);
    const [languageText, setLanguageText] = useState(languageInformation.language ? (languageInformation.language === 'uk-UA' ? 'UA' : languageInformation.language) : 'eng');

    useEffect(() => {
        setLanguageText(languageInformation.language ? (languageInformation.language === 'uk-UA' ? 'UA' : languageInformation.language) : 'eng')
    }, [languageInformation]);

    function replaceTextLanguage(e) {
        setLanguageOpen(!languageOpen);
        setLanguageText(e.target.innerText);
    }

    const wishArray = useSelector(state => state.favorite.favorite)
    const СhangeLanguageHeader = (e, route, language) => {

        replaceTextLanguage(e);
        dispatch(changeLanguage({
            route: route,
            language: language,
        }));
        setTimeout(() => {
            window.location.reload()
        }, 100)

    }

    const {
        data: categories,
        loading: loadingCategories,
        errorCategories
    } = useFetch(process.env.REACT_APP_API_URL + `/categories?locale=${languageInformation.language}&populate=*&filters[displayedInTheMenu][$eq]=true&sort[0]=sort`);

    //state drawer
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const drawerOpenClick = (newState) => {
        setDrawerOpen(newState);
    }
    useEffect(() => {
        dispatch(setOpenCart(drawerOpen !== false))
    }, [drawerOpen]);

    const productsInCart = useSelector(state => state.cart.products)
    const productsLength = productsInCart.length
    // Випадашки в шапці
    const [activeIndex, setActiveIndex] = useState(null);
    const handleItemClick = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));

    };

    const handleChildItemClick = () => {
        setActiveIndex(null); // Закрити всі випадаючі списки
        setBurgerOpen(false);
    };

    const languageTag = <div className={languageOpen ? 'header__language active' : 'header__language'}>

        <div onClick={() => {
            setLanguageOpen(!languageOpen)
        }} className="header__language-title d-flex a-i-center">
            <p>{languageText == 'cs' ? 'CZ' : languageText}</p>
            <img src={process.env.PUBLIC_URL + '/img/language__arrow.svg'} alt="arrow"/>
        </div>

        <ul className="header__language-body">

            <li onClick={(e) => {
                СhangeLanguageHeader(e, '', 'en')
            }}><span>ENG</span></li>
            {/*<li onClick={(e) => {*/}
            {/*    СhangeLanguageHeader(e, 'uk/', 'uk-UA')*/}
            {/*}}><span>UA</span></li>*/}
            <li onClick={(e) => {
                СhangeLanguageHeader(e, 'cs/', 'cs')
            }}><span>CZ</span></li>

        </ul>
    </div>

    const headerRef = useRef(null)
    useEffect(() => {
        const headerElement = headerRef.current;

        if (headerElement) {
            const observer = new ResizeObserver(() => {
                dispatch(setHeaderHeight(headerElement.clientHeight));
            });

            observer.observe(headerElement);

            // Clean up the observer when the component unmounts or when headerElement changes
            return () => observer.disconnect();
        }
    }, [headerRef]);

    function generateSets(element) {
        try {
            if (element?.collectionsTitle.length > 2) {
                return [{
                    attributes: {
                        title: element?.collectionsTitle,
                        url:
                            "collections/" +
                            element.collectionsTitle +
                            "/" +
                            element.masterId +
                            `?category=${element.masterId}`,
                    }
                }]
            }
        } catch (error) {

            return []
        }
        return []
    }

    return (
        <header ref={headerRef}>

            <div className='header__first d-flex a-i-center j-c-space w100'>
                <Link to='/' className='full'>
                    <img src={process.env.PUBLIC_URL + '/img/header__logo-full.png'} alt="header__logo"/>
                </Link>


                <Link to='/'>
                    <img className='short' src={process.env.PUBLIC_URL + '/img/header__logo-short.png'} alt="header__logo-short"/>
                </Link>
                <div className="header__search-wr">

                    <div className="header__search d-flex a-i-center j-c-space">
                        <input value={searchBody} onChange={(e) => {
                            setSearchBody(e.target.value)
                        }} type="text" placeholder={text[0]?.attributes?.searchPlaceholder}/>

                        <Link to={'/search/' + searchBody} className='search__button'>{text[0]?.attributes?.searchButton}</Link>
                    </div>

                </div>


            </div>

            <div className="header__second d-flex a-i-center j-c-space">


                <div className="header__language-wr">
                    {languageTag}
                    <SelectCurrency/>
                </div>

                <li className="header__list d-flex a-i-center">


                    {
                        errorCategories ? "error on server" : (
                            loadingCategories ?
                                <>
                                    <div className="skeleton-header"><Skeleton/></div>
                                    <div className="skeleton-header"><Skeleton/></div>
                                    <div className="skeleton-header"><Skeleton/></div>
                                    <div className="skeleton-header"><Skeleton/></div>
                                    <div className="skeleton-header"><Skeleton/></div>
                                    <div className="skeleton-header"><Skeleton/></div>
                                </>
                                :
                                categories.map((el, index) =>
                                    <HeaderListItem
                                        title={el}
                                        languageInformation={languageInformation}
                                        key={index}
                                        subcategories={el.attributes?.subcategories.data}
                                        isActive={activeIndex === index}
                                        onItemClick={() => handleItemClick(index)}
                                        onChildItemClick={handleChildItemClick}
                                    />
                                )
                        )
                    }

                </li>

                <div className="header__nav d-flex a-i-center">

                    <Link to='/favorites' className={"header__drawerContainer"}>
                        <img src={process.env.PUBLIC_URL + '/img/favorite.svg'} alt="favorite"/>

                        {wishArray.length > 0 &&
                            <span className={"header__drawer_counter"}>
							{wishArray.length}
						</span>}
                    </Link>

                    <Link to={token.length > 0 ? '/profile' : '/login'}>
                        <img src={process.env.PUBLIC_URL + '/img/account.svg'} alt="account"/>
                    </Link>
                    <div className={"header__drawerContainer"}>
                        {productsLength > 0 &&
                            <span className={"header__drawer_counter"}>
							{productsLength}
						</span>}
                        <img onClick={drawerOpenClick} src={process.env.PUBLIC_URL + '/img/drawer.svg'} alt="drawer"/>
                    </div>

                </div>

            </div>

            <div className="header__mobile d-flex w100 a-i-center j-c-space">

                <div onClick={() => {
                    setBurgerOpen(!burgerOpen)
                }} className={burgerOpen ? 'header__burger active' : 'header__burger'}><span></span></div>

                <Link to='/'>
                    <img className='header__mobile-logo' src={process.env.PUBLIC_URL + '/img/header__logo-full.png'} alt="logo full"/>
                </Link>


                <div className={"header__drawerContainer"}>
                    {productsLength > 0 &&
                        <span className={"header__drawer_counter"}>
							{productsLength}
						</span>}
                    <img onClick={drawerOpenClick} className='header__mobile-drawer' src={process.env.PUBLIC_URL + '/img/drawer__mobile.png'}
                         alt="drawer"/>
                </div>

            </div>
            {


                categories.map((el, index) => <div key={index + '123123123'}
                                                   className={activeIndex == index ? "header__list-body active d-flex a-i-center" : "header__list-body d-flex" +
                                                       " a-i-center"}>
                        <div className={'menuLinksContainers'} key={index}>
                            {[...generateSets(el.attributes), ...el.attributes.subcategories.data, ...el.attributes.categoriesInCategories.data].map((link, index2) => {
                                if (!link?.attributes?.title) return "no title"
                                return <Link onClick={handleChildItemClick} key={index2}
                                             to={link.attributes.url}>{link.attributes.title.length > 30 ? link.attributes.title.slice(0, 30) + '...' : link.attributes.title}
                                </Link>
                            })}

                        </div>
                    </div>
                )
//...data[0].attributes.subcategories.data, ...data[0].attributes.categoriesInCategories.data
                // categories[activeIndex].subcategories.map((el, index) => (
                //     <Link onClick={handleChildItemClick} key={el.id + index}
                //           to={el.attributes.url}>{el.attributes.title.length > 30 ? el.attributes.title.slice(0, 30) + '...' : el.attributes.title}
                //     </Link>
                // ))


            }
            {/*{(activeIndex) ?*/}
            {/*    <div className={activeIndex ? "header__list-body active d-flex a-i-center" : "header__list-body d-flex a-i-center"}>*/}
            {/*        {*/}

            {/*            categories[activeIndex].subcategories.map((el, index) => (*/}
            {/*                <Link onClick={handleChildItemClick} key={el.id + index}*/}
            {/*                      to={el.attributes.url}>{el.attributes.title.length > 30 ? el.attributes.title.slice(0, 30) + '...' : el.attributes.title}*/}
            {/*                </Link>*/}
            {/*            ))*/}
            {/*        }*/}

            {/*    </div> : ''*/}
            {/*}*/}
            <div className={burgerOpen ? 'burger__body active' : 'burger__body'}>

                <div className="burger__body-content">

                    {
                        errorCategories ? "error on server" : (
                            loadingCategories ? "loading.." :
                                categories.map((el, index) =>
                                    <HeaderListMobile
                                        key={el.id + index}
                                        title={el}
                                        subcategories={[...generateSets(el.attributes), ...el.attributes.subcategories.data, ...el.attributes.categoriesInCategories.data]}
                                        languageInformation={languageInformation}
                                        isActive={activeIndex === index}
                                        onItemClick={() => handleItemClick(index)}
                                        onChildItemClick={handleChildItemClick}
                                    />
                                )
                        )
                    }


                </div>

                <div onClick={() => {
                    setBurgerOpen(!burgerOpen)
                }} className="header__mobile-buttons d-flex a-i-center">
                    <Link to={token.length > 0 ? '/profile' : '/login'} className="header__mobile-btn">{text[0]?.attributes?.burgerButton1}</Link>
                    <Link to='/favorites' className="header__mobile-btn">{text[0]?.attributes?.burgerButton2}</Link>
                </div>

                <div className="header__mobile-social-networks d-flex a-i-center">
                    <a href='/test'><img src={process.env.PUBLIC_URL + '/img/facebook.svg'} alt="facebook"/></a>
                    <a href='/test'><img src={process.env.PUBLIC_URL + '/img/instagram.png'} alt="instagram"/></a>
                </div>

                <div className="header__language-wr  header__language-wr_mobile">
                    {languageTag}
                    <SelectCurrency/>

                </div>

            </div>

            <Drawer
                onClick={drawerOpenClick}
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                text={text ? text : []}
            />

        </header>
    );
}

export default Header;
